<ion-item *ngIf="!hideInputField" (click)="openModal()">
    <ion-label position="floating">{{ label }}</ion-label>
    <ion-input [placeholder]="placeholder"
               readonly
               [value]="value | ObjectSelectionDisplayPipe: titleFields">
    </ion-input>

    <ion-badge *ngIf="value && badgeFields" slot="start"
               color="primary">
        {{ value | ObjectSelectionDisplayPipe: badgeFields }}
    </ion-badge>
</ion-item>
<ion-modal *ngIf="isModalOpen" [isOpen]="isModalOpen" (didDismiss)="closeModal()">
    <ng-template>
        <mits-loading-spinner *ngIf="(indexDataService.isLoading$ | async) && !dataInitialized"
                              text="Daten werden geladen..."></mits-loading-spinner>
        <mits-header [title]="title" [modal]="true" (closed)="closeModal()"></mits-header>
        <ion-content>
            <div class="header">
                <ion-item *ngIf="!multiple && value">
                    <ion-badge *ngIf="badgeFields" slot="start"
                               color="primary">
                        {{ value | ObjectSelectionDisplayPipe: badgeFields }}
                    </ion-badge>
                    <div class="label">
                        <ion-label class="title ion-text-wrap">{{ value | ObjectSelectionDisplayPipe: titleFields }}
                        </ion-label>
                        <ion-label
                                class="subtitle ion-text-wrap">{{ value | ObjectSelectionDisplayPipe: subtitleFields }}
                        </ion-label>
                    </div>
                    <ion-icon name="close-circle-outline" color=danger (click)="selectObject(undefined)"></ion-icon>
                </ion-item>
                <div class="searchbar">
                    <div *ngIf="enableScanner" class="scannerButtonContainer">
                         <mits-scanner-button [size]="28" [width]="42" (scannerData)="scannedData($event)"></mits-scanner-button>
                    </div>

                     <ion-searchbar *ngIf="!disableSearch" mode="ios" placeholder="Suchen..."
                                    [formControl]="indexDataService.searchField"></ion-searchbar>
                </div>
            </div>
            <ion-list class="itemsList" *ngIf="indexDataService.data$ | async">
                <ion-item lines="none" *ngIf="!(indexDataService.isLoading$ | async) && dataInitialized && (indexDataService.data$ | async).length === 0">
                    <ion-text color="primary">
                        <p>Keine Daten
                            {{indexDataService.searchField?.value?.trim()?.length > 0? 'für "' + indexDataService.searchField?.value +'"' : ''}}
                            gefunden...</p>
                    </ion-text>
                </ion-item>
                <ng-container *ngFor="let object of indexDataService.data$ | async; trackBy: trackById">
                    <ion-item *ngIf="!isSelected(object) || multiple" lines="full"
                              (click)="!multiple ? selectObject(object) : null">
                        <ion-checkbox *ngIf="multiple" slot="start" [checked]="isSelected(object)"
                                      (ionChange)="onCheckboxChange($event, object)"></ion-checkbox>
                        <ion-badge *ngIf="badgeFields" slot="start" color="primary">
                            {{ object | ObjectSelectionDisplayPipe: badgeFields }}
                        </ion-badge>
                        <div class="label">
                            <ion-label
                                    class="title ion-text-wrap">{{ object | ObjectSelectionDisplayPipe: titleFields }}
                            </ion-label>
                            <ion-label
                                    class="subtitle ion-text-wrap">{{ object | ObjectSelectionDisplayPipe: subtitleFields }}
                            </ion-label>
                        </div>
                    </ion-item>
                </ng-container>
                <ion-infinite-scroll
                        *ngIf="loadingType === 'infinite-scroll' && (indexDataService.hasMorePages$ | async)"
                        (ionInfinite)="onIonInfinite($event)">
                    <ion-infinite-scroll-content></ion-infinite-scroll-content>
                </ion-infinite-scroll>
            </ion-list>
        </ion-content>
        <ion-footer>
            <ion-progress-bar *ngIf="indexDataService.isLoading$ | async" type="indeterminate"></ion-progress-bar>
            <mits-pagination *ngIf="loadingType=== 'pagination'"
                             [currentPage]="indexDataService.currentPage.value"
                             [lastPage]="indexDataService.lastPage$ | async"
                             (pageChanged)="pageChanged($event)">
            </mits-pagination>
            <ion-button class="ion-no-padding ion-no-margin" color=primary expand=full (click)="closeModal()">
                Schließen
            </ion-button>
        </ion-footer>
    </ng-template>
</ion-modal>
