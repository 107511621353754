<ion-card-header *ngIf="title">
  <ion-card-title color="primary">{{title}}</ion-card-title>
</ion-card-header>
<ion-card-content>
  <ion-item lines="none" color="none">
    <ion-textarea [placeholder]="placeholder" [debounce]="debounce" [disabled]="disabled" [rows]="rows"
      [(ngModel)]="value" (ionChange)="change()" [autoGrow]="autoGrow">
    </ion-textarea>
  </ion-item>
  <ion-item lines="none" *ngIf="useBoilerplates">
    <ion-button fill="outline" size="small" (click)="presentBoilerplates($event)" [disabled]="disabled">
      Textbausteine hinzufügen
    </ion-button>
    <mits-object-select #mitsObjectSelectBoilerplates [objects]="boilerplates" [shadowInput]="true"
      objectTypeTitle="Textbaustein" [enableMultiSelect]="true" [displayNameVariables]="{title: ['name']}">
    </mits-object-select>
  </ion-item>
</ion-card-content>